<script setup lang="ts">
import { upperFirst } from 'scule'
import { WishlistToggle } from '#components'
import type { CurrencyCode, OrderLineFragment } from '#graphql-operations'

const props = withDefaults(defineProps<{
  line: OrderLineFragment
  currency: CurrencyCode
  editable?: boolean
}>(), {
  editable: true,
})

const emit = defineEmits<{
  (e: 'remove:orderLine', orderLine?: OrderLineFragment): void
  (e: 'change:orderLine', orderLine?: OrderLineFragment, quantity?: number): void
  (e: 'click:link', orderLine?: OrderLineFragment): void
}>()

const { editable, line } = toRefs(props)

const isLoading = ref(false)

const { t } = useI18n()

const { removeOrderLine, adjustOrderLine } = useActiveOrder()
const priceFormatter = usePriceFormatter()

const badges = computed(() => line.value.productVariant.product.badges ?? [])

const discountPercentage = computed(() => {
  return calculateDiscountPercentage(line.value.productVariant.priceBeforeDiscount, line.value.discountedUnitPrice)
})

const isDiscounted = computed(() => {
  return discountPercentage.value > 0
  return line.value.productVariant?.priceBeforeDiscount && line.value.unitPrice !== line.value.productVariant.priceBeforeDiscount
})

async function handleOrderLine(action: 'remove' | 'adjust', line: OrderLineFragment, quantity?: number) {
  if (isLoading.value)
    return

  if (quantity !== undefined) {
    if (quantity < 1)
      return handleOrderLine('remove', line)
    else if (quantity > 99)
      quantity = 99
  }

  isLoading.value = true

  if (action === 'remove') {
    await removeOrderLine(line)
    emit('remove:orderLine', line)
  }
  else if (quantity !== undefined) {
    await adjustOrderLine(line, quantity)
    emit('change:orderLine', line, quantity)
  }

  isLoading.value = false
}
</script>

<template>
  <div class="relative grid of-hidden">
    <Loading v-if="isLoading" />

    <!-- there was of-hidden but it cuts price so i remove it -->
    <div class="of-hidden">
      <div class="relative flex p-inline-4 md:p-inline-6" data-testid="productCardContainer">
        <div class="flex flex-col">
          <div class="relative w-[120px] flex shrink-0">
            <NuxtLinkLocale
              class="relative hfull wfull flex-1 color-inherit decoration-none"
              data-testid="productImageContainer"
              :to="`/products/${line.productVariant.product.id}/${line.productVariant.product.slug}`"
            >
              <div class="absolute left-0 right-0 top-0 z1">
                <WishlistToggle
                  v-slot="{ alert, isItemWishlisted, isAnimating, handleWishlistClick }"
                  :product-id="line.productVariant.product.id" :product-variant-id="line.productVariant.id"
                >
                  <button
                    type="button"
                    class="group/wishlist absolute right-1.5 top-1.5 m0 h6 w6 flex cursor-pointer items-center justify-center of-visible border-0 rd-1/2 bg-none p1.25 c-black outline-none transition-opacity duration-300 ease-in will-change-opacity,transform"
                    aria-label="Přidat položku na seznam přání"
                    data-testid="addToWishlist"
                    :aria-pressed="isItemWishlisted"
                    @click.stop.prevent="handleWishlistClick"
                  >
                    <Icon
                      :name="isItemWishlisted ? 'ic:heart-fill' : 'ic:heart'"
                      class="block h6 w6 shrink-0 grow-0 basis-6 text-primary"
                      :class="
                        {
                          'group-hover/wishlist:animate-[2s_ease-in-out_0s_infinite_normal_none_running_heartbeat]': !isItemWishlisted && !isAnimating,
                          'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseOutward]': isAnimating && isItemWishlisted,
                          'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseInward]': isAnimating && !isItemWishlisted,
                        }
                      "
                      mode="svg"
                      :data-testid="`wishListIcon-${isItemWishlisted ? 'filled' : 'empty'}`"
                    />
                    <Icon
                      name="ic:heart-fill"
                      class="absolute block h6 w6 shrink-0 grow-0 basis-6 fill-white stroke-4 stroke-white text-white -z1"
                      mode="svg"
                    />
                  </button>
                  <div class="absolute left-0 top-0 z1 wfull translate-z-0">
                    <Transition
                      enter-active-class="duration-550 ease-[cubic-bezier(0.75,0.23,0.57,0.65)] will-change-[transform,opacity]"
                      enter-from-class="transform translate-y-[-100%] opacity-0"
                      enter-to-class="transform translate-y-0 opacity-100"
                      leave-active-class="duration-550 ease-[cubic-bezier(0.75,0.23,0.57,0.65)] will-change-[transform,opacity]"
                      leave-from-class="transform translate-y-0 opacity-100"
                      leave-to-class="transform translate-y-[-100%] opacity-0"
                    >
                      <div
                        v-if="alert"
                        class="fixed left-0 top-0 z300 wfull flex animate-in bg-destructive p3.25 text-center text-sm text-black leading-6 md:py0"
                        type="FAILURE"
                        direction="down"
                      >
                        <span data-testid="toastContent" class="wfull flex grow-1 flex-col md:p2">
                          <span>{{ alert }}</span>
                        </span>
                      </div>
                    </Transition>
                  </div>
                </WishlistToggle>
              </div>

              <div data-testid="badgeAndHintContainer" class="absolute bottom-1 z1 wfull flex flex-col">
                <div data-testid="badgesList" class="relative -bottom-2.5">
                  <div data-testid="BadgesContainer" class="absolute bottom-5 top-auto wfull flex flex-col items-start p0">
                    <!--                    <div -->
                    <!--                      v-for="(badge, index) in badges" :key="index" -->
                    <!--                      data-testid="badge-GENERIC" -->
                    <!--                      class="mr1.25 mt1 h5 max-w-full w-fit inline-flex items-center gap1.25 ws-nowrap rd-r-1 bg-background py0.5 pl4 pr1 text-xs text-primary fw600 last-of-type:mr0 [&>span]:block [&>span]:of-hidden [&>span]:text-ellipsis [&>span]:whitespace-nowrap [&>span]:leading-[1.3]" -->
                    <!--                    > -->
                    <!--                      <span class="outline-none"> -->
                    <!--                        {{ badge.text }} -->
                    <!--                      </span> -->
                    <!--                    </div> -->
                    <div
                      v-if="discountPercentage"
                      data-testid="badge-SALE"
                      class="mtpx h5 max-w-full w-fit inline-flex items-center gap1.25 ws-nowrap rd-r-1 bg-red-500 py0.5 pl4 pr1 text-xs text-white fw600 last-of-type:mr0 [&>span]:block [&>span]:of-hidden [&>span]:text-ellipsis [&>span]:ws-nowrap [&>span]:leading-[1.3]"
                    >
                      <span>-{{ discountPercentage }}%</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-testid="productImage"
                class="relative h0 wfull of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)] [&>img]:absolute [&>img]:inset-0 [&>img]:m-auto [&>img]:hfull [&>img]:max-h-full [&>img]:max-w-full [&>img]:wfull [&>img]:object-cover [&>img]:object-center-center"
              >
                <NuxtImg
                  height="100%"
                  width="100%"
                  decoding="async"
                  loading="lazy"
                  sizes="33.333vw"
                  :src="line.featuredAsset?.preview"
                  :alt="line.productVariant.product.name"
                  data-testid="productImageView"
                  class="aspect-3/4 rd-0.5 align-bottom"
                />
              </div>
            </NuxtLinkLocale>
          </div>

          <div v-if="editable" class="mt1.25 flex-1 md:hidden">
            <div
              data-testid="productQtyChangeSection"
              class="w30 flex flex-row justify-between border border-input rd-1 bg-background p1 text-base text-muted-foreground"
            >
              <button
                type="button"
                class="h6 w6 flex cursor-pointer items-center justify-center rd-4 border-none bg-white p0 hover:bg-slate-100"
                aria-disabled="false"
                aria-label="Decrease quantity"
                data-testid="productQtyDecreaseButton"
                @click="handleOrderLine('adjust', line, line.quantity - 1)"
              >
                <Icon name="ph:minus-bold" />
              </button>

              <div
                data-testid="selectedProductQty"
                class="mx-auto flex items-center text-sm text-primary fw600"
              >
                {{ line.quantity }}
              </div>

              <button
                type="button"
                class="h6 w6 flex cursor-pointer items-center justify-center rounded-4 border-none bg-white p0 hover:bg-slate-100"
                aria-disabled="false"
                aria-label="Increase quantity"
                data-testid="productQtyIncreaseButton"
                @click="handleOrderLine('adjust', line, line.quantity + 1)"
              >
                <Icon name="ph:plus-bold" />
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-1 flex-col items-start self-stretch justify-between pt3 md:ml6">
          <div data-testid="productDetailsSection" class="text-sm text-black fw600 leading-[1.33] tracking-normal">
            <div data-testid="productDescriptionContainer" class="wfull flex flex-row flex-nowrap content-center items-center justify-between">
              <div class="flex flex-col gap1.25">
                <NuxtLinkLocale
                  data-testid="productPath"
                  class="color-inherit decoration-none"
                  :to="`/products/${line.productVariant.product.id}/${line.productVariant.product.slug}`"
                >
                  <!--                  <div data-testid="productBrandName" class="mb1.25 text-xs fw900"> -->
                  <!--                    {{ line.productVariant.product.name }} -->
                  <!--                  </div> -->
                  <div data-testid="productName" class="mb1.25 text-xs fw900">
                    {{ line.productVariant.product.name }}
                  </div>

                  <template v-if="line.productVariant.options.length">
                    <div
                      v-for="option in line.productVariant.options" :key="option.code"
                      :data-testid="`product${upperFirst(option.group.code)}`"
                      class="text-xs fw600"
                    >
                      {{ option.name }}
                    </div>
                  </template>
                </NuxtLinkLocale>
              </div>
            </div>
          </div>

          <div v-if="editable"class="flex-1 hidden md:mt5 md:block">
            <div
              data-testid="productQtyChangeSection"
              class="w30 flex flex-row justify-between border border-input rd-1 bg-background p1 text-base text-muted-foreground"
            >
              <button
                type="button"
                class="h6 w6 flex cursor-pointer items-center justify-center rd-4 border-none bg-white p0 hover:bg-slate-100"
                aria-disabled="false"
                aria-label="Decrease quantity"
                data-testid="productQtyDecreaseButton"
                @click="handleOrderLine('adjust', line, line.quantity - 1)"
              >
                <Icon name="ph:minus-bold" />
              </button>

              <div
                data-testid="selectedProductQty"
                class="mx-auto flex items-center text-sm text-primary fw600"
              >
                {{ line.quantity }}
              </div>

              <button
                type="button"
                class="h6 w6 flex cursor-pointer items-center justify-center rounded-4 border-none bg-white p0 hover:bg-slate-100"
                aria-disabled="false"
                aria-label="Increase quantity"
                data-testid="productQtyIncreaseButton"
                @click="handleOrderLine('adjust', line, line.quantity + 1)"
              >
                <Icon name="ph:plus-bold" />
              </button>
            </div>
          </div>
        </div>

        <div class="relative flex flex-col items-end self-stretch justify-between">
          <div v-if="editable">
            <button
              data-testid="removeProduct"
              class="relative h9 w9 flex shrink-0 grow-0 basis-9 cursor-pointer items-start of-visible border-0 rd-1/2 bg-transparent px1.25 outline-none will-change-opacity,transform"
              @click="handleOrderLine('remove', line)"
            >
              <Icon mode="svg" name="ph:trash" class="block h6 w6 shrink-0 grow-0 basis-6 text-slate-400" />
              <div class="absolute inset-0" />
            </button>
          </div>

          <div class="absolute bottom-0 right-0">
            <div class="flex flex-col items-end justify-center ws-nowrap">
              <div
                class="relative wfull transition-height duration-500 ease-in-out"
                :class="isDiscounted ? 'h10' : 'h6'"
              >
                <div class="absolute hfull wfull will-change-opacity">
                  <div data-testid="priceBox" class="flex flex-col">
                    <div
                      v-if="isDiscounted"
                      class="relative flex flex-wrap items-center justify-end md:mt0"
                    >
                      <div class="flex items-center gap1 first:mr1 last:mr0">
                        <span data-testid="strikePrice0" class="text-2.5 text-slate-400 fw600 leading-4 decoration-line-through">
                          {{ priceFormatter(line.productVariant?.priceBeforeDiscount * line.quantity, props.currency) }}
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-wrap items-baseline justify-end gap1">
                      <div data-testid="finalPrice" class="flex items-baseline gap1">
                        <span
                          class="text-base fw700"
                          :class="{ 'text-red-500': isDiscounted }"
                        >
                          {{ priceFormatter(line.discountedLinePrice, props.currency) }}
                        </span>
                      </div>
                    </div>
                    <!--                    <span data-testid="lowestProductPrice30D" class="text-2.25 text-slate-400 fw600 leading-3.5"> -->
                    <!--                      Nejvýhodnější cena za posledních 30 dní**: 2 015 Kč (+18 %) -->
                    <!--                    </span> -->
                  </div>
                </div>
              </div>

              <div
                v-if="line.quantity > 1"
                data-testid="unitPriceBox"
                class="text-left"
              >
                <span
                  data-testid="unitPrice"
                  class="text-xs text-slate-400 fw600 leading-[1.5]"
                >
                  {{ t('product.n_per_item.label', [priceFormatter(line.discountedUnitPrice, props.currency)]) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
